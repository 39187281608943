.bgOffWhite {
  background-color: var(--offwhite);
}
.root {
  background-color: var(--offwhite);
  position: relative;
}
.upperRoot {
  position: sticky;
  top: 5rem;
  padding: 1.5rem 3.75rem;
  max-height: calc(100dvh - 5.2rem - 3.8rem);
}
.projectTitleDetails {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 0.613rem;
}
.projectTitleCategory {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  gap: 5px;
  justify-self: flex-start;
  font-size: var(--mid);
  color: black;
}

.primaryTypeNames {
  color: var(--red);
}
.primaryTypeNames {
  color: var(--red);
}

.dotsBlack,
.dotsRed {
  background-color: black;
  height: 4px;
  width: 4px;
  border-radius: 100%;
}
.dotsRed {
  background-color: var(--red);
  border-radius: 90%;
  height: 4px;

  /* width: fit; */
}
.projectTitleLocation {
  color: black;
}
.projectTitleDetailsFirstChild {
  font-size: 34px;
}
.projectTitleDetailsSecondChild {
  font-size: var(--md);
}
.swiperContainer {
  overflow: hidden;
  max-height: 80%;
}
.swiperSlideContainer {
  cursor: grab;
}
.swiperSlideContainer:active {
  cursor: grabbing;
}

.slideImage {
  width: 100%;
  height: 0;
  padding-bottom: 75%; /* 4:3 aspect ratio (adjust as needed) */
  position: relative;
  overflow: hidden; /* Hide overflowing content */
}
.sliderImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  box-shadow: 0px 0px 0px 0px rgba(31, 36, 45, 0);

  top: 0;
  left: 0;
}
.stickyBar {
  position: -webkit-sticky;
  position: sticky;
  top: 10rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black;
  padding: 1.406rem 3.75rem;
  min-height: 3.75rem;
}
.stickyBar > div:first-child {
  font-size: var(--lg);
}
.stickyBar > div:nth-child(2) {
  font-size: var(--mid);
  color: var(--slate);
}
.aboutButton {
  user-select: none;
  cursor: pointer;
}
.crossIcon {
  padding: 0.625rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-grey);

  border-radius: 100%;
}

.lowerRoot {
  margin-bottom: 6.25rem;
  padding: 1rem 3.75rem;
}

.upperSection {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  font-size: var(--md);
}
.projectDetailsOne {
  display: flex;
  flex-direction: column;
  width: 56%;
  gap: 0.5rem;
  text-align: justify;
}
.projectDetailsOne,
.projectDetailsOneSection {
  line-height: 140%;
  max-width: 75%;
  font-size: var(--md);
  /* word-break: break-all; */
  margin-bottom: 3.125rem;
  text-align: justify;
}
.projectDetailsTwo {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  gap: 1.25rem;
  text-align: left;
}
.projectDetailsFirst {
  display: flex;
  gap: 3.125rem;
}
.commonDetailHeading {
  font-size: var(--xs);
  color: var(--light-slate);
}
.commonDetailContent {
  font-size: var(--sm);
  color: black;
  line-height: 120%;
}
.lowerSection {
  display: flex;
  flex-direction: column;
  gap: 3.125rem;
}
.constructionDetails {
  display: flex;
  flex-direction: column;

  gap: 1.25rem;
}
.constructionImage {
  max-width: 75%;
}
.constructionContent {
  font-size: var(--sm);
  line-height: 120%;
  max-width: 22.22vw;
}
.showAll {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: var(--md);
  text-align: center;
}
.mobileSwiper {
  display: none;
}
.showMore {
  display: none;
}
@media screen and (max-width: 1368px) {
}

@media screen and (max-width: 1024px) {
  .upperRoot {
    position: sticky;
    top: 5rem;
    padding: 1.42rem;
  }
  .stickyBar {
    color: black;
    padding: 1.42rem;
    height: 3rem;
  }
  .mobileSwiper {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    max-height: 75dvh;
    overflow-y: scroll;
    scrollbar-width: thin; /* For Firefox */
  }

  .mobileSwiper::-webkit-scrollbar {
    display: none;
  }

  .lowerRoot {
    margin-bottom: 3.125rem;
    padding: 0.675rem 1.25rem;
  }
  .upperSection {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-size: var(--mid);
    margin-bottom: 3.125rem;
  }

  .projectDetailsOne,
  .projectDetailsOneSection {
    line-height: 100%;
    width: 100%;
    max-width: 100%;
    font-size: var(--mid);
    margin-bottom: 3.125rem;
    text-align: justify;
  }
  .projectDetailsOneSection {
    margin-bottom: 0;
    text-align: justify;
  }

  .showMore {
    display: block;
    align-self: flex-start;
    font-size: var(--xs);
    line-height: 150%;
    text-decoration: underline;
    color: var(--red);
  }
  .projectTitleDetailsFirstChild {
    font-size: var(--md);
  }

  .constructionDetails {
    flex-direction: column;
    gap: 0.313rem;
  }
  .constructionImage {
    max-width: 100%;
  }
  .constructionContent {
    font-size: var(--xs);
    line-height: 100%;
    max-width: 100%;
    color: black;
    letter-spacing: 0.21px;
  }
  .showAll {
    font-size: var(--sm);
  }
}

@media screen and (max-width: 576px) {
}
