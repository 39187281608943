.root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--bg-landing);
  padding: 3.75rem;
  color: var(--offwhite);
  border-color: var(--white);
  fill: var(--offwhite);
  stroke: var(--offwhite);
  z-index: 1000;
}
.footerUpper {
  display: flex;
  justify-content: space-between;
}
.footerLower {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}
.footerHeading {
  font-size: var(--md);
  color: var(--text-dim);
}
.firstChild,
.thirdChild,
.thirdChild > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.25rem;
  width: fit-content;
}
.thirdChild {
  text-align: right;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1.25rem;
}
.thirdChild > div {
  gap: 6px;
}
.thirdChild_button {
  cursor: pointer;
  font-size: var(--sm);
  width: fit-content;
  padding: 0.625rem;
  border: 1px solid;
  color: inherit;
  background-color: transparent;
}
.socialIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.875rem;
}
.socialIcons a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: 0.5rem;
  border: 1px solid;
  margin-bottom: 1.875rem;
}
.footerAdress {
  line-height: 110%;
  font-weight: 400;
  font-size: var(--mid);
}
.footerAdress p {
  margin-bottom: 5px;
}

.horizontalLine,
.horizontalLineBlack {
  height: 1px;
  width: 100%;
  background-color: var(--white);
  border-radius: 50%;

  opacity: 0.5;
  margin-top: 1.25rem;
  margin-bottom: 3.75rem;
}
.horizontalLineBlack {
  background-color: black;
}
.madeBy {
  font-size: var(--sm);
  color: var(--text-dim);
}
.madeBy span {
  color: var(--offwhite);
}
@media screen and (max-width: 1345px) {
}

@media screen and (max-width: 1024px) {
  .root {
    padding: 2.5rem 1.25rem;
  }

  .firstChild,
  .thirdChild,
  .thirdChild > div {
    width: 100%;
  }
  .footerHeading,
  .footerAdress {
    font-size: var(--sm);
  }

  .footerAdress {
    line-height: 140%;
  }

  .footerUpper {
    flex-direction: column;
  }
  .firstChild {
    order: 1;
    display: flex;
  }

  .thirdChild {
    text-align: left;
    align-items: flex-start;
    gap: 1.875rem;
    margin: 2.5rem 0;
  }

  .socialIcons {
    display: flex;
    flex-direction: row;

    gap: 1.875rem;
    width: 100%;
  }
  .footerLower {
    width: 100%;
  }

  .horizontalLine,
  .horizontalLineBlack {
    margin: 2.5rem 0;
    margin-top: 0;
  }
  .madeBy {
    /* margin: 2.5rem 0; */
    margin: 16px 0;
  }
}

@media screen and (max-width: 576px) {
}
