.root {
  margin-bottom: 10rem;
}
.slide {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1.25rem;
  padding: 2.5rem;
  color: var(--white);
  font-size: var(--sm);
  background-color: var(--slate);
  word-break: keep-all;

  line-height: 140%;
  height: 22rem;
  min-height: 370px;
}
.quote {
  position: absolute;
  left: -1.25rem;
  top: -0.25rem;
}
.slideTitle {
  font-size: var(--lg);
}

.customWrapper {
  min-height: 30rem;
  align-items: center;
}
.readMore {
  display: flex;
  align-items: center;
  text-align: left;
  margin-top: 0.875rem;
  color: var(--text-menu);
}
.arrowSlide {
  /* padding-top: 10px; */
  margin-left: 8px;
  /* background-color: red; */
}
.slideContent {
  text-align: justify;
  font-weight: 400;
}

.closeButton {
  display: flex;
  background-color: var(--text-menu);
  border-radius: 100%;
  padding: 0.625rem;
  color: black;
  transition: box-shadow 0.3s ease;
}

@media screen and (max-width: 1024px) {
  .customWrapper {
    min-height: 21rem;
  }

  .slideTitle {
    font-size: var(--md);
  }

  .slideContent {
    text-align: justify;
    font-size: var(--sm);
  }

  .slideSub {
    font-size: var(--xxs);
  }
  /* styles.module.css */
  .modalOverlay {
    position: fixed;
    z-index: 9999990;
    padding-top: 25%;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    background-color: #4f4f4f;
  }

  .modal {
    display: flex;
    color: #f8f8f8;
    justify-content: space-between;
    gap: 1.5rem;
    flex-direction: column;
    align-items: center;
    background-color: #4f4f4f;

    border-radius: 8px;
    padding: 20px;
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
  }
  .closeModal {
    text-decoration: underline;
    color: #f8f8f8;
  }
  .modalContent {
    /* Add your own styles for the modal content */
  }
}
